export const setStorageJSON = (name: string, data: any): void => {
  try {
    window.localStorage.setItem(name.toLowerCase(), JSON.stringify(data))
  } catch (e) {
    console.error(e)
  }
}

export const getStorageJSON = (name: string): any => {
  try {
    return JSON.parse(window.localStorage.getItem(name.toLowerCase()) || 'null')
  } catch (e) {
    console.error(e)
  }
}
